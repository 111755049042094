import { Vector3, HemisphereLight, Scene, Mesh, PlaneGeometry, MeshPhongMaterial, WebGLRenderer, DirectionalLight,OrthographicCamera } from 'three';
import Stats from 'three/examples/jsm/libs/stats.module.js';

import * as THREE from 'three'

//import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'



let camera, scene, renderer, stats;
//let controls;

let loadModelErrorAction;
function initScene(_width, _height) {

    const canvas = document.querySelector('#three')

    camera = new OrthographicCamera( _width / - 2, _width / 2, _height / 2, _height / - 2, 1, 1000 );
    //camera = new PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 1000);
    camera.position.set(0, 1, 10);
    //camera.lookAt(0, 0, 0);

    scene = new Scene();
    window.THREE = THREE;
    window.scene = scene;
    scene.name="threeScene"
    scene.add(camera);
    const hemiLight = new HemisphereLight(0xffffff, 0x444444);
    hemiLight.name="hemiLight";
    hemiLight.position.set(0, 20, 0);
    scene.add(hemiLight);

    const dirLight = new DirectionalLight(0xffffff);
    dirLight.name="dirLight";
    dirLight.position.set(- 3, 10, - 10);
    dirLight.castShadow = true;
    dirLight.shadow.camera.top = 2;
    dirLight.shadow.camera.bottom = - 2;
    dirLight.shadow.camera.left = - 2;
    dirLight.shadow.camera.right = 2;
    dirLight.shadow.camera.near = 0.1;
    dirLight.shadow.camera.far = 40;
    scene.add(dirLight);


    const mesh = new Mesh(new PlaneGeometry(100, 100), new MeshPhongMaterial({ color: 0x999999, depthWrite: false }));
    mesh.name="plane";
    mesh.rotation.x = - Math.PI / 2;
    //mesh.receiveShadow = true;
    scene.add(mesh);


screen2World(window.innerWidth/5,0);
    renderer = new WebGLRenderer({ canvas, antialias: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    //renderer.toneMapping = ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1;



    stats = new Stats();
    document.body.appendChild(stats.dom);

    //controls = new OrbitControls(camera, renderer.domElement);
    //controls.addEventListener('change', render); // use if there is no animation loop
    // controls.minDistance = 2;
    // controls.maxDistance = 100;
    // controls.target.set(0, 0, 0);

    // // controls.enableZoom = false;
    // controls.maxPolarAngle = 60 * (Math.PI / 180);
    // controls.enabled = true;
    // controls.update();
    //赋值全局变量

    window.addEventListener('resize', onWindowResize);
   

    function onWindowResize() {

        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();

        renderer.setSize(window.innerWidth, window.innerHeight);

        render();

    }

}

function updateScene() {


    stats.begin();

    // if (controls.enabled) {
    //     controls.update();
    // }
    render();
    stats.end();

}
function screen2World(x, y) {

  console.log("world")
    // 屏幕坐标转标准设备坐标
    let x1 = (x / window.innerWidth) * 2 - 1;
    let y1 = -(y / window.innerHeight) * 2 + 1;
    //标准设备坐标(z=0.5这个值比较靠经验)
    let stdVector = new Vector3(x1, y1, 0.5);
    //世界坐标
    let worldVector = stdVector.unproject(camera);
    console.log("worldVector",worldVector)
    return worldVector;
}
function render() {

    renderer.render(scene, camera);
}
function addToScene(obj) {
    scene.add(obj);
}

function setLoadModelErrorAction(action) {
    loadModelErrorAction = action;
}
function loadModelError(url) {
    console.log(url + "loadModelError")
    if (loadModelErrorAction) {
        loadModelErrorAction();
    }
}
function returnRender() {
    return renderer;
}

export {
    initScene,
    addToScene,
    updateScene,
    loadModelError,
    setLoadModelErrorAction,
    returnRender,
    screen2World
}