import { createApp } from "vue";
import App from "./App";
import starWeb3d from "./web3D/startWeb3D";
import Raf from "./web3D/raf";
import store from "./store";
// import ElementPlus from "element-plus";
// import "element-plus/dist/index.css";

createApp(App)
  .use(store)
  .mount(document.getElementById("app"));
 
  const assetPath = "";
store.commit('setWebGLAssetPath', assetPath);
//store.commit('setWebGLAssetPath', "");
// store.commit('setWebGLAssetPath', assetPath);
// store.commit('setCoinUIXY',[300,20]);
const web3DStar = new starWeb3d(12,3.375,
  () => {
    console.log('init end');
 });

// window.addEventListener('click', onMouseClick, false);
window.addEventListener('keydown', keydown);
 function keydown(e) {
  if(e.key=="q"){
    web3DStar.moveToTarget(true);
  }
  if(e.key=="w"){
    web3DStar.moveToTarget(false);
  }

  if (e.key == "1") {
   
    web3DStar.changeHumanAnimation(0);
  }
  if (e.key == "2") {
    web3DStar.changeHumanAnimation(1);
  }
  if (e.key == "3") {
    web3DStar.changeHumanAnimation(2);
  }
  if (e.key == "4") {
    web3DStar.changeHumanAnimation(3);
  }
  if (e.key == "5") {
    web3DStar.changeHumanAnimation(4);
  }

}
// function onMouseClick() {



// }
function update() {
  web3DStar.update();
}
Raf.suscribe('update', () => { update() })
