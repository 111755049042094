import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-62cb9e57"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  id: "three"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("canvas", _hoisted_1);
}