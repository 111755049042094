import { initScene, updateScene } from "./sceneManager";
import { initHuman, changeAnimation,updateHuman,moveToTarget } from "./digitalHuman";


class starWeb3d {

    constructor(_width, _height,_callback) {

        this.callback = _callback;
        this.initDone = false;
        this.init(_width, _height);
    }

    init(_width, _height) {

        initScene(_width, _height);
        initHuman().then(() => {
            if (this.callback) {
                this.callback();
            }
            this.initDone = true;setTimeout(() => {
                moveToTarget(true);
            }, 3000);
            
        });

    }


    update() {
        if (this.initDone) {
            updateScene();
            updateHuman();
        }
    }

    changeHumanAnimation(num) {
        changeAnimation(num);
    }
moveToTarget(isleft){
    moveToTarget(isleft);
}

}
export default starWeb3d;