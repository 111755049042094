import { createStore } from "vuex";

const store = createStore({
    state: {
  
        //服务器资源地址 
        //https://xyao-shop-dev.oss-cn-shenzhen.aliyuncs.com/foodstar
        //webGLAssetPath: "https://xyao-shop-dev.oss-cn-shenzhen.aliyuncs.com/foodstar"
        webGLAssetPath: ""
    },
    mutations: { // 用来修改state和getters里面的数据
   
        setWebGLAssetPath(state, _webGLAssetPath) {
            //console.log(_webGLAssetPath);
            state.webGLAssetPath = _webGLAssetPath;
            //console.log(state.webGLAssetPath);
        },
       

    },
    getters: { // 相当于计算属性
    },
    actions: { // vuex中用于发起异步请求
    },
    modules: {// 拆分模块
    }

})
export default store