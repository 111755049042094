import { Clock, AnimationMixer, LoopOnce } from 'three';
import store from '../store';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { addToScene, loadModelError } from './sceneManager';
import TWEEN from 'three/examples/jsm/libs/tween.module.js';

let mixerUpdateDelta;
let humanObj;
let mixer;
let clock;
let assetPath;
let actions = [];
let loader;
let promiseArray = [];
let actionIdle, actionTalk, actionTurnRight, actionWalk, actionTurnLeft, actionWalkLeft, actionWalkRight;
let animationIndex = 0;
let originPos = 2.6;
let IsMoveAnimationEnd = true;
function initHuman() {
	clock = new Clock();
	loader = new GLTFLoader();
	assetPath = store.state.webGLAssetPath;

	promiseArray.push(
		new Promise((resolve) => {

			loader.load(assetPath + '/models/scene.glb', (gltf) => {
				if (gltf == null) {
					loadModelError(assetPath + '/models/L3J_Ani_0709.glb');
					alert('Error loading model');
					return;
				}
				humanObj = gltf.scene;
				humanObj.name = "human";
				//model.rotation.y = Math.PI;
				humanObj.position.set(originPos, -0.5, 0);
				humanObj.scale.set(1.05, 1.05, 1.05);
				mixer = new AnimationMixer(humanObj);
				const animations = gltf.animations;
				//idle talk trun_r walk rurn_l
				actionIdle = mixer.clipAction(animations[0]);
				actionTalk = mixer.clipAction(animations[1]);
				actionTurnRight = mixer.clipAction(animations[3]);
				actionTurnRight.setLoop(LoopOnce);
				actionTurnRight.clampWhenFinished = true;
				actionWalk = mixer.clipAction(animations[4]);
				actionTurnLeft = mixer.clipAction(animations[2]);
				actionTurnLeft.setLoop(LoopOnce);
				actionTurnLeft.clampWhenFinished = true;
				actionWalkLeft = mixer.clipAction(animations[5]);
				actionWalkRight = mixer.clipAction(animations[4]);
				actions = [actionIdle, actionTalk, actionTurnRight, actionWalk, actionTurnLeft, actionWalkLeft, actionWalkRight];
				// for (let i = 0; i < actions.length; i++) {
				// 	console.log(actions[i].name);
				// 	actions[i].play();
				// 	actions[i].setEffectiveWeight(0);
				// }
				activateAllActions();
				setWeight(actions[0], 1);
				animationIndex = 0;
				//actions.Walk.setEffectiveWeight(1);
				addToScene(humanObj);
				resolve();
			})
		})

	)


	return Promise.all(promiseArray);
}

// function deactivateAllActions() {

// 	actions.forEach( function ( action ) {

// 		action.stop();

// 	} );

// }
function activateAllActions() {



	actions.forEach(function (action) {

		action.play();
		setWeight(action, 0);
	});

}
let speed = 0.5;
let rotateSpeed =0.5;
function moveToTarget(isLeft) {
	if (!IsMoveAnimationEnd) return;
	IsMoveAnimationEnd = false;
	if (isLeft) {
	
		changeAnimation(4, 0.5, false).then(() => {
		changeAnimation(6, 0.5).then(() => {
			new TWEEN.Tween(humanObj.position).to({ x: -1 * originPos }, (originPos / speed) * 1000)
				.onComplete(() => {

					changeAnimation(2, rotateSpeed,true).then(() => {
						changeAnimation(0, 0.5).then(() => {
							IsMoveAnimationEnd = true;
							setTimeout(() => {
								moveToTarget(false);
							}, 3000);
						});
					});
				})
				.start();
		});

	});

	} else {
		changeAnimation(2, rotateSpeed,false).then(() => {
			changeAnimation(5, 0.5).then(() => {
				new TWEEN.Tween(humanObj.position).to({ x: originPos }, (originPos / speed) * 1000)
					.onComplete(() => {
						changeAnimation(4, rotateSpeed).then(() => {
							changeAnimation(0, 0.5).then(() => {
								IsMoveAnimationEnd = true;
								setTimeout(() => {
									moveToTarget(true);
								}, 3000);
							});
						});
					})
					.start();
			});

		});

		///没有左转右转动画
		// changeAnimation(3, rotateSpeed);
		// 	new TWEEN.Tween(humanObj.rotation).to({ y: Math.PI / 2 }, rotateSpeed * 1000)
		// 		.onComplete(() => {
		// 			new TWEEN.Tween(humanObj.position).to({ x: originPos }, (originPos / speed) * 1000)
		// 				.onComplete(() => {
		// 					changeAnimation(2, rotateSpeed);
		// 					new TWEEN.Tween(humanObj.rotation).to({ y: 0 }, rotateSpeed * 1000)
		// 						.start()
		// 				})
		// 				.start();
		// 		})

		// 		.start();
	}

}
function changeAnimation(_num, _time = 1 ,isFade=true) {

	return new Promise((resolve) => {

		
		//actions[_num].crosssFadeFrom(actions[animationIndex], _time, true);
		if (isFade) {
			setWeight(actions[_num], 1);
		actions[_num].time = 0;
			actions[animationIndex].crossFadeTo(actions[_num], _time, true);
			setTimeout(() => {
				setWeight(actions[animationIndex], 0);
				console.log('change' + _num);
				animationIndex = _num;
				resolve();
			}, _time * 1000);
		} else {
			let aniLength=actions[_num].length;
			changeAniWithoutCrossFade(_num);
			setTimeout(() => {
				console.log('change' + _num);
				animationIndex = _num;
				resolve();
				
			}, aniLength * 1000);
		
		}

		// mixer.addEventListener( 'loop', change )
		// function change() {
		// 	console.log('change');
		// 	animationIndex = _num;
		// 	mixer.removeEventListener( 'loop', change )
		// 	resolve();
		// }

	});


}
function changeAniWithoutCrossFade(_num) {
	for (let i = 0; i < actions.length; i++) {
		//actions[i].time = 0;
		setWeight(actions[i], 0);
	}
	actions[_num].time = 0;
	setWeight(actions[_num], 1);



}
function setWeight(action, weight) {

	action.enabled = true;
	action.setEffectiveTimeScale(1);
	action.setEffectiveWeight(weight);

}




function updateHuman() {

	TWEEN.update();
	mixerUpdateDelta = clock.getDelta();
	mixer.update(mixerUpdateDelta);



}
export { initHuman, updateHuman, changeAnimation, moveToTarget };